// This function is used to convert the properties from version 1 to version 0
export const convertPropertiesV1toV0 = (newProperty) => {
  const oldData = [];

  newProperty.forEach((newData) => {
    const oldProperty = {
      propertyId: newData.id,
      customerId: newData.customer?.id,
      isPartner: newData.isPartner,
      propertyName: newData.name,
      centralCoordinates: {
        latitude: newData.centralCoordinates
          ? newData.centralCoordinates.latitude
          : null,
        longitude: newData.centralCoordinates
          ? newData.centralCoordinates.longitude
          : null,
      },
      customerName: newData.customer?.name,
      address: {
        street: newData.address ? newData.address.street : null,
        city: newData.address ? newData.address.city : null,
        state: newData.address ? newData.address.state : null,
        zipCode: newData.address ? newData.address.zipCode : "",
      },
      zoomLevel: newData.zoomLevel,
      workOrders: newData.workOrders
        ? newData.workOrders.map((wo) => ({
            workOrderId: wo.workOrderId,
            workOrderName: wo.workOrderName,
            technicianUserId: wo.technician1Employee?.id || null,
            technicianUserName: wo.technician1Employee?.firstName + " " + wo.technician1Employee?.lastName || null,
            contractorId: wo.partnerId || null,
            contractorName: wo.partnerName || null,
            checkInTime: wo.checkInTime || null,
            checkOutTime: wo.checkOutTime || null,
          }))
        : [],
      geometries: newData.geometries
        ? newData.geometries.map((geo) => ({
            geometryId: geo.id,
            listOfCoordinates: geo.listOfCoordinates.map((coord) => ({
              latitude: coord.latitude,
              longitude: coord.longitude,
            })),
          }))
        : [],
      isGeofenceCreated: newData.isGeofenceCreated,
    };
    oldData.push(oldProperty);
  });

  return oldData;
};

// This function is used to convert the devices from version 1 to version 0
export const convertDevicesV1toV0 = (inputData) => {
  const oldData = [];
  inputData.data.forEach((newData) => {
    const oldDevice = {
      userId: newData.id,
      userName: newData.type  === "MOBILE" ? newData.name : '',
      vehicleId: newData.id,
      vehicleName: newData.name,
      startDateTimeOfPairing: "",
      endDateTimeOfPairing: "",
      deviceType: newData.type === "MOBILE" ? 1 : 0,
      lastUpdateDateTimeOfDevice: "",
      lastUpdateDateTimeOfData: "",
      plate: newData.plate,
      make: newData.make,
      model: newData.model,
      lastCoordinate: {
        timestamp: newData.lastCoordinate
          ? newData.lastCoordinate.timestamp
          : null,
        latitude:
          newData.lastCoordinate && newData.lastCoordinate.latitude
            ? newData.lastCoordinate.latitude
            : 0,
        longitude:
          newData.lastCoordinate && newData.lastCoordinate.longitude
            ? newData.lastCoordinate.longitude
            : 0,
      },
    };
    oldData.push(oldDevice);
  });
  return oldData;
};

// This function is used to convert the workorders from version 1 to version 0
export const convertWorkordersUserV1toV0 = (newWorkorder) => {
  const oldData = [];
  newWorkorder.forEach((newData) => {
    const oldWorkorder = {
      id: newData.id,
      name: `${newData.firstName} ${newData.lastName}`,
      userId: newData.id,
      email: newData.email,
      photoUrl: newData.photoUrl,
      colorCode: newData.colorHexCode || "",
      userProfile: newData.role,
      mobile: newData.mobile,
      totalWorkingHours: "",
      serviceTypes: null,
    };
    oldData.push(oldWorkorder);
  });

  return oldData;
};

// This function is used to convert the contractors from version 1 to version 0
export const convertContractorsV1toV0 = (newContractor) => {
  const oldData = [];

  newContractor.forEach((newData) => {
    const oldContractor = {
      id: newData.id,
      name: newData.name,
      isActive: newData.isActive,
      colorCode: newData.colorHexCode || "",
    };
    oldData.push(oldContractor);
  });

  return oldData;
};

// This function is used to convert the coordinates from version 1 to version 0
export const convertCoordinatesV1toV0 = (newData) => {
  const oldData = {
    // equipmentId: newData.equipmentId,
    coordinates: newData.coordinates.map((coord) => ({
      equipmentId: coord.equipmentId,
      latitude: coord.latitude,
      longitude: coord.longitude,
      Timestamp: coord.timestamp,
      Processed: coord.processed,
      Visited: coord.visited,
      CreatedAt: coord.createdAt,
      UpdatedAt: coord.updatedAt,
    })),
  };

  return oldData;
};

// This function is used to convert the workorders request body from version 0 to version 1
export const convertRequestBodyOfWorkorderV0toV1 = (oldData) => {
  const newData = {
    technician1EmployeeId: oldData.user_1,
  };

  return newData;
};

// This function is used to convert the properties request body from version 0 to version 1
export const convertRequestBodyOfPropertyV0toV1 = (oldProperty) => {
  const newProperty = {
    geometries: oldProperty.geometries.map((geometry) => ({
      geometryId: geometry.geometryId,
      listOfCoordinates: geometry.listOfCoordinates.map((coord) => ({
        latitude: coord.latitude,
        longitude: coord.longitude,
      })),
    })),
  };
  return newProperty;
};
