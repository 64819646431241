import * as actionType from "./actionTypes";

// set loading status to true in redux store
export const startLoading = () => {
  return {
    type: actionType.START_LOADING,
  };
};

// set loading status to false in redux store
export const stopLoading = () => {
  return {
    type: actionType.STOP_LOADING,
  };
};
