import * as actionType from "../actions/actionTypes";

// initialState
const initialState = {
  isLoading: false,
};

/**
 * Capture actions dispatched and modify state accordingly
 * @param {*} state -> updated state
 * @param {*} action -> type of action dispatched
 */
const loading = (state = initialState, action) => {
  switch (action.type) {
    // set loading to true
    case actionType.START_LOADING:
      return {
        ...state,
        isLoading: true,
      };

    // set loading to false
    case actionType.STOP_LOADING:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default loading;
